exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-customer-js": () => import("./../../../src/pages/customer.js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-signupcompleted-js": () => import("./../../../src/pages/signupcompleted.js" /* webpackChunkName: "component---src-pages-signupcompleted-js" */),
  "component---src-pages-users-js": () => import("./../../../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-complaints-page-js": () => import("./../../../src/templates/complaints-page.js" /* webpackChunkName: "component---src-templates-complaints-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-packages-page-js": () => import("./../../../src/templates/packages-page.js" /* webpackChunkName: "component---src-templates-packages-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-signup-page-js": () => import("./../../../src/templates/signup-page.js" /* webpackChunkName: "component---src-templates-signup-page-js" */),
  "component---src-templates-terms-and-conditions-page-js": () => import("./../../../src/templates/terms-and-conditions-page.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-page-js" */),
  "component---src-templates-terms-of-business-page-js": () => import("./../../../src/templates/terms-of-business-page.js" /* webpackChunkName: "component---src-templates-terms-of-business-page-js" */)
}

